import { createStore } from "vuex"
import AuthModule from "./modules/auth"
import LoadingModule from "./modules/loading"
import CampaignsModule from "./modules/campaigns"
import UserModule from "./modules/user"
import shoppingCart from "./modules/shoppingCart"
import generalStore from "./modules/generalStore"

const store = createStore({
  modules: {
    auth: AuthModule,
    loading: LoadingModule,
    campaigns: CampaignsModule,
    user: UserModule,
    shoppingCart: shoppingCart,
    generalStore: generalStore,
  },
})

export default store

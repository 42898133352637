<script>
import ApiService from "../service.api";
import { mapActions, mapGetters } from "vuex";
import { dateToDayMonth, translate } from "../helper.auth";
import { IonicSafeString } from "@ionic/vue";
import moment from "moment";

export default {
  name: "CampaignService",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("campaigns", {
      campaignsData: "getCampaignsData",
    }),
  },
  methods: {
    ...mapActions("campaigns", {
      saveCampaignsToStorage: "saveCampaignsToStorage",
      setOnBoardingIdToStorage: "setOnBoardingIdToStorage",
    }),
    isNormalCampaign(campaignData) {
      return campaignData.id_tipo_campagna === 2;
    },
    isOnBoarding(campaignData) {
      return campaignData.id_tipo_campagna === 1;
    },
    isDataVisualization(campaignData) {
      return campaignData.id_tipo_campagna === 3;
    },
    isSupervisedCampaign(campaignData) {
      return campaignData.view_supervisor;
    },
    getCampaignDefaultUrl(campaignData, ignoreMission) {
      if (this.isSupervisedCampaign(campaignData)) {
        let params = { id: campaignData.id };
        return this.$router.resolve({
          name: "campaign_ranking",
          params: params,
        }).href;
      }
      if (!ignoreMission) {
        if (
          (campaignData.mission_description &&
            this.$root.translate(campaignData.mission_description)) ||
          campaignData.url_mission_cover
        ) {
          return this.$router.resolve({
            name: "campaign_mission",
            params: { id: campaignData.id },
          }).href;
        }
      }
      let params = { id: campaignData.id, title: campaignData.title };

      const defaultRoute = "campaign_kpi";
      let route = defaultRoute;
      // if(this.isOnBoarding(campaignData)){
      //   route = 'campaign_learning';
      // }else if(campaignData.sezioniCampagna && campaignData.sezioniCampagna.length > 1){
      //   route = 'campaign_menu';
      // }
      if (
        campaignData.sezioniCampagna &&
        campaignData.sezioniCampagna.length > 1
      ) {
        route = "campaign_menu";
      } else if (
        campaignData.sezioniCampagna &&
        campaignData.sezioniCampagna.length === 1
      ) {
        return this.getCampaignSectionRoute(
          campaignData,
          campaignData.sezioniCampagna[0]
        );
      }

      return this.$router.resolve({
        name: route,
        params: params,
      }).href;
    },
    getCampaigns(options) {
      let resolveReturn = (data, options, resolve) => {
        if (options && options.filterForHome) {
          data = data.filter((x) => {
            return !this.isOnBoarding(x);
          });
        }
        resolve(data);
      };
      const promise = new Promise((resolve) => {
        let data = this.campaignsData;
        if (!data || (options && options.forceRefresh)) {
          let urlToCall = "CampaignManager/GetUserCampaings";
          // let urlToCall = "CampaignManager/GetUserCampaingsApp/1"

          // if (this.$route.path.includes("debug")) {
          //   urlToCall = "CampaignManager/GetUserCampaingsApp/0"
          // } else {
          //   urlToCall = "CampaignManager/GetUserCampaingsApp/1"
          // }

          ApiService.get(urlToCall).then((res) => {
            let mappedData = res.data.map((item) => {
              let x = {
                ...item,
                title: translate(item.title),
                description: translate(item.description),
              };
              x.startEnd = new IonicSafeString(
                this.$t("campaign.start_end", {
                  from: dateToDayMonth(x.data_inizio),
                  to: dateToDayMonth(x.data_fine),
                })
              ).value;
              x.totalNumberOfDays = moment(x.data_fine).diff(
                moment(x.data_inizio),
                "days"
              );
              x.remainingNumberOfDays = -moment().diff(
                moment(x.data_fine),
                "days"
              );
              x.remainingDaysString = new IonicSafeString(
                this.$t("campaign.remaining_days", {
                  value: x.remainingNumberOfDays,
                })
              ).value;
              //x.currentPercentage = Math.round(x.remainingNumberOfDays / x.totalNumberOfDays) * 100;
              x.currentPercentage = Math.round(x.percentualeCompletamento);
              x.currentPercentageVal = Math.min(
                1,
                (x.totalNumberOfDays - x.remainingNumberOfDays) /
                  x.totalNumberOfDays
              );
              x.isEnded = moment() > moment(x.data_fine);
              x.isFuture = moment() < moment(x.data_inizio);
              x.isOpen = !x.isEnded && !x.isFuture;
              x.isClosed = x.isEnded || x.isFuture;
              if (!x.isOpen) {
                x.currentPercentage = 0;
              }

              // Add on boarding if available to Menu
              if (this.isOnBoarding(x)) {
                this.setOnBoardingIdToStorage(x.id);
              }

              return x;
            });
            this.saveCampaignsToStorage(mappedData);

            resolveReturn(mappedData, options, resolve);
          });
        } else {
          resolveReturn(data, options, resolve);
        }
      });
      return promise;
    },
    getCampaignSectionRoute(campaignData, campaignSection) {
      const params = {
        id: campaignData.id,
        title: campaignData.title,
      };
      let query = {};
      let name = "campaign_menu." + campaignSection.modulo.toLowerCase();
      let route = null;
      if (campaignSection.modulo == "KPI") {
        route = "campaign_kpi";
      } else if (campaignSection.modulo == "LEARNING") {
        route = "campaign_learning";
      } else if (campaignSection.modulo == "SOCIAL") {
        route = "social_login";
        // route = "campaign_social";
        query.campaignId = this.campaignData.id;
      } else if (campaignSection.modulo == "ORDINE") {
        route = "kpiChat";
        query.campaignId = campaignData.id;
      } else if (campaignSection.modulo == "INDUCTION") {
        route = "InductionPlan";
      } else if (campaignSection.modulo == "EVALONETOMANY") {
        route = "EvalOneToMany";
      } else if (campaignSection.modulo == "CONTINOUSIMPROVEMENT") {
        route = "ContinousImprovement";
      } else if (campaignSection.modulo == "QuizOnboarding") {
        route = "QuizOnboarding";
      } else if (campaignSection.modulo == "PLANOGRAM") {
        route = "planogram-viewer";
      } else if (campaignSection.modulo == "CHECKLIST") {
        route = "checklist";
      } else {
        return null;
      }
      return this.$router.resolve({ name: route, params, query }).href;
    },
    getCampaign(id) {
      return new Promise((resolve) => {
        this.getCampaigns().then((data) => {
          resolve(data.find((c) => c.id == id));
        });
      });
    },
  },
};
</script>

import { Plugins } from "@capacitor/core"
import axios from "axios"

const { Storage } = Plugins

const state = () => ({
  userData: JSON.parse(localStorage.getItem("whappyUserData")) || null,
})

const getters = {
  getUserData(state) {
    return state.userData
  },
}

const actions = {
  async saveUserToStorage({ commit }, payload) {
    commit("saveUser", payload.data)
  },
}

const mutations = {
  saveUser(state, payload) {
    if (!payload) {
      state.userData = null
      localStorage.removeItem("whappyUserData")
      this.$router.push({ path: "perform-logout" })
    } else {
      state.userData = payload
      localStorage.setItem("whappyUserData", JSON.stringify(payload))
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

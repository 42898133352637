import {Plugins} from "@capacitor/core";
import axios from "axios";

const {Storage} = Plugins;

const state = () => ({
    campaignsData: null,
    onBoardingId: null,
});

const getters = {
    getCampaignsData(state) {
        return state.campaignsData;
    },
    getOnBoardingId(state) {
        return state.onBoardingId;
    },
    getOnBoardingCampaign(state) {
        return state.campaignsData.find(x => x.id === state.onBoardingId);
    },
};

const actions = {
    async saveCampaignsToStorage({commit}, payload) {
        commit("saveCampaigns", payload);
    },
    async setOnBoardingIdToStorage({commit}, payload) {
        commit("setOnBoardingId", payload);
    }
};

const mutations = {
    saveCampaigns(state, payload) {
        if (!payload) {
            state.campaignsData = null;
        } else {
            const newCampaignsData = payload;
            state.campaignsData = newCampaignsData;
        }
    },
    setOnBoardingId(state, payload) {
        state.onBoardingId = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import {Plugins} from "@capacitor/core";
import axios from "axios";


const {Storage} = Plugins;

const state = () => ({
    refCount: 0,
    isLoading: false
});
const getters = {
    isLoading(state) {
        return state.isLoading;
    },
};

const mutations = {
    upLoading(state) {
        state.refCount++;
        state.isLoading = (state.refCount > 0);
    },
    downLoading(state) {
        state.refCount--;
        state.isLoading = (state.refCount > 0);
    },
    // loading(state, isLoading) {
    //     console.log({isLoading});
    //     if (isLoading) {
    //         state.refCount++;
    //         state.isLoading = true;
    //     } else if (state.refCount > 0) {
    //         state.refCount--;
    //         state.isLoading = (state.refCount > 0);
    //     }
    //     // console.log(state);
    // }
};

const actions = {
    setLoading({commit}, isLoading) {
        if (isLoading) {
            commit("upLoading");
        } else {
            commit("downLoading");
        }
        // console.log(state);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

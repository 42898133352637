<template>
  <ion-header>
    <!-- <ion-toolbar>
      <ion-title>{{ this.data.title }}</ion-title>
    </ion-toolbar> -->
    <span class="closeButton" @click="closeModal">X</span>
  </ion-header>
  <ion-content className="ion-padding">
    <div ref="maincontentwrapper">
      <div
        class="maincontent"
        ref="maincontent"
        :class="{
          hasSmallImage: this.data.img_small,
          has2SmallImages: this.data.img_small_2,
        }"
      >
        <img
          class="mainImage"
          @load="reposition"
          v-if="this.data.img_big"
          :src="this.data.img_big"
          @error="handleImgError"
        />
        <h4
          class="ion-margin-top ion-margin-bottom"
          v-if="!this.data.customtype || this.data.customtype !== 'title_sub'"
          v-show="this.data.title"
          v-html="this.data.title"
        ></h4>
        <p
          class="ion-margin-top ion-margin-bottom"
          v-show="this.data.body"
          v-html="this.data.body"
          style="font-size: 1rem"
        ></p>
        <img
          class="smallImage"
          @load="reposition"
          v-if="this.data.image"
          :src="this.data.image"
          @error="handleImgError"
        />
        <img
          class="smallImage"
          @load="reposition"
          v-if="this.data.img_small"
          :src="this.data.img_small"
          @error="handleImgError"
        />
        <img
          class="smallImage"
          @load="reposition"
          v-if="this.data.img_small_2"
          :src="this.data.img_small_2"
          @error="handleImgError"
        />
        <p
          class="smallImage-label"
          v-show="this.data.img_small_label"
          v-html="this.data.img_small_label"
        ></p>
        <h4
          v-if="this.data.customtype && this.data.customtype === 'title_sub'"
          v-show="this.data.title"
          v-html="this.data.title"
        ></h4>
        <div v-if="this.data.customtype && this.data.customtype === 'feedback'">
          <form action="" @submit.prevent="onFeedbackSubmit">
            <div class="feedbackform">
              <div class="feedbackformstar">
                <fieldset class="rating">
                  <template v-for="i in 5" v-bind:key="i">
                    <input
                      type="radio"
                      name="feedbackformstar"
                      :id="'star' + (5 - i)"
                      :value="5 - i"
                    />
                    <label :for="'star' + (5 - i)"></label>
                  </template>
                </fieldset>
                <div class="clearfix"></div>
              </div>
              <div class="feedbackformicon">
                <fieldset class="icons">
                  <template v-for="i in 4" v-bind:key="i">
                    <input
                      type="radio"
                      name="feedbackformicon"
                      :id="'icon' + i"
                      :value="'icon' + i"
                    />
                    <label :class="'labelicon' + i" :for="'icon' + i"></label>
                  </template>
                </fieldset>
              </div>
              <div class="feedbackformtext">
                <textarea placeholder="Aggiungi anche un commento.."></textarea>
              </div>
            </div>
          </form>
        </div>
        <ion-button class="mainButton" @click="action()"
          >{{
            this.data.action_label
              ? this.data.action_label
              : $t("notification.close")
          }}
        </ion-button>
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotificationModal",
  props: {
    data: { type: Object },
  },
  setup() {
    const closeModal = () => {
      modalController.dismiss();
    };

    return { closeModal };
  },
  data() {
    return {
      content: "Content",
    };
  },
  mounted() {
    this.reposition();
    this.$nextTick(() => {
      this.reposition();
    });
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    finalreposition() {
      const maincontentHeight = this.$refs.maincontent.clientHeight;
      const maincontentIonHeight = window.innerHeight * 0.86 - 20;
      if (maincontentHeight < maincontentIonHeight) {
        this.$refs.maincontentwrapper.style["padding-top"] =
          Math.round((maincontentIonHeight - maincontentHeight) / 2) + "px";
      }
    },
    reposition() {
      setTimeout(() => {
        this.finalreposition();
      }, 1);
      setTimeout(() => {
        this.finalreposition();
      }, 10);
      setTimeout(() => {
        this.finalreposition();
      }, 100);
    },
    action() {
      const actionsMap = {
        ranking: "campaign_ranking",
        badge: "campaign_badge",
        classroom: "campaign_learning",
        ticket: "report",
        awards: "campaign_award",
        mission: "campaign_mission",
        social: "campaign_social",
        kpi: "campaign_kpi",
        saleschat: "kpiChat",
        DescrizioneOrdine: "DescrizioneOrdine",
      };
      if (this.data.url) {
        this.$root.externalLinkBlank(this.data.url);
      } else if (
        this.data.action_type == "saleschat" ||
        this.data.action_type == "ticket"
      ) {
        this.$router.push({
          name: actionsMap[this.data.action_type],
        });
      } else if (this.data.action_type == "DescrizioneOrdine") {
        this.$router.push({
          name: actionsMap[this.data.action_type],
          params: { id: this.data.campaign_id },
        });
      } else if (this.data.action_type && this.data.campaign_id) {
        if (actionsMap[this.data.action_type]) {
          this.$router.push({
            name: actionsMap[this.data.action_type],
            params: { id: this.data.campaign_id },
          });
        }
      }
      modalController.dismiss();
    },
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButton },
});
</script>
<style lang="scss">
.closeButton {
  position: absolute;
  right: 22px;
  top: 16px;
  z-index: 10;
  font-size: 18px;
  background: #fff;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  color: var(--ion-color-secondary);
}

.mainImage {
  margin: 20px;
  max-height: 190px;
}

.smallImage {
  margin: 20px;
}

.maincontent.hasSmallImage {
  .mainImage,
  .smallImage {
    max-height: 70px;
  }

  &.has2SmallImages {
    .smallImage {
      max-width: 70px;
    }
  }
}

.maincontent h3 {
  text-transform: none;
  margin: 10px;
}

.maincontent p {
  margin: 10px;
}

.maincontent .mainButton {
  margin: 20px;
}

h3 {
  font-size: 25px;
}

p {
  font-size: 23px;
}

.smallImage-label {
  font-size: 19px;
}

.feedbackform {
  input[type="radio"] {
    display: none;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.feedbackformstar {
  background: #fff;
  margin: 23px -24px 12px;

  .rating {
    text-align: center;
    float: none;
    display: inline-block;
    border: none;
  }

  .rating:not(:checked) > input {
    position: absolute;
    top: -9999px;
    clip: rect(0, 0, 0, 0);
  }

  .rating:not(:checked) > label {
    float: right;
    width: 1em;
    padding: 0 0.1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 200%;
    line-height: 1.2;
    color: #ddd;
    filter: grayscale(1);

    margin: 14px 5px 7px;
    background: url(/assets/icon/feedback/star.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
  }

  /*.rating:not(:checked) > label:before {
    //content:'★ ';
    content:' ';
    display:block;
    background: url('/assets/override/images/star.svg');
    background-size: cover;
    width:40px;
    height:40px;
    filter:none;
  }*/
  .rating > input:checked ~ label {
    color: #f70;
    filter: none;
  }

  .rating:not(:checked) > label:hover,
  .rating:not(:checked) > label:hover ~ label {
    color: gold;
    filter: none;
  }

  .rating > input:checked + label:hover,
  .rating > input:checked + label:hover ~ label,
  .rating > input:checked ~ label:hover,
  .rating > input:checked ~ label:hover ~ label,
  .rating > label:hover ~ input:checked ~ label {
    color: #ea0;
    filter: none;
  }

  .rating > label:active {
    position: relative;
  }
}

.feedbackformicon {
  margin: 24px auto 5px;
  width: 208px;
  background: #fff;
  border-radius: 30px;

  .icons label {
    background: url("/assets/icon/feedback/star.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin: 10px 10px 0px;
    filter: grayscale(1);
    display: inline-block;

    &.labelicon0 {
      background-image: url("/assets/icon/feedback/icon0.svg");
    }

    &.labelicon1 {
      background-image: url("/assets/icon/feedback/icon1.svg");
      position: relative;
      top: 2px;
    }

    &.labelicon2 {
      background-image: url("/assets/icon/feedback/icon2.svg");
      position: relative;
      top: -2px;
    }

    &.labelicon3 {
      background-image: url("/assets/icon/feedback/icon3.svg");
    }
  }

  input:checked + label,
  input:hover + label {
    filter: none;
  }
}

.feedbackformtext {
  width: 100% !important;
  text-align: center;

  textarea {
    border: 0;
    border-radius: 5px;
    font-size: 12px;
    //padding: 2px 7px;
    height: 72px;
    width: 250px;
    color: #000;
    padding: 8px;
  }
}
</style>

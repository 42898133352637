const CLIENT_CONFIG = {
  name: "Masterclass",
  numTutorialImages: 16,
  showAvatarCampaigns: true,
  apiEndpoint: "https://api-masterclass.whappy.it/api/",
  primaryColor: "#FFA721",
  primaryColor_2: "#FFA721",
  secondaryColor: "#06308C",
  secondaryColor_2: "#06308C",
  thirdaryColor: "#FFD600",
  thirdaryColor_2: "#FFD600",
  graphLightColor_1: "#FFA721",
  graphLightColor_2: "#06308C",
  graphDarkColor_1: "#FFA721",
  graphDarkColor_2: "#06308C",
};
export default CLIENT_CONFIG;

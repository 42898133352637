import moment from "moment";

export function jwtDecrypt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

export function translate(t) {
    return t[window.i18n.global.locale.value + '-' + window.i18n.global.locale.value.toUpperCase()];
}

export function dateToDayMonth(d) {
    return moment(d).format('DD/MM');
}

export function dateToHourMinute(d) {
    return moment(d).format('HH:mm');
}

export function externalLinkBlank(url) {
    console.log('opening external url', url);
    window.open(url, '_blank').focus();
}

export function loadFacebookScript(appId){
    if(window.loadFacebookScriptPromise){
        if (window.fbAsyncInitResolved) {
            return Promise.resolve(true);
        }else{
            return window.loadFacebookScriptPromise;
        }
    }
    window.loadFacebookScriptPromise = new Promise((resolve) => {
        if (!window.fbAsyncInit) {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: appId,
                    status: true,
                    xfbml: true,
                    version: 'v2.9'
                });
                window.FB.AppEvents.logPageView();
                resolve(true);
                window.fbAsyncInitResolved = true;
            };

            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
    });
    return window.loadFacebookScriptPromise;
}

export function shortenText(str,maxLength){
    var trimmedString = str.substr(0, maxLength);
    if(trimmedString.lastIndexOf(" ")!==-1) {
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
    }
    if(str.length>maxLength){
        trimmedString+='..';
    }
    return trimmedString;
}
export function shortenTextForTitle(str){
    str = str.replace(/(\\r\\n|\\n|\\r)/gm, "");
    str = str.replace(/(\r\n|\n|\r)/gm, "");
    return shortenText(str,30);
}
import { initializeApp } from "firebase/app"

import { getMessaging, getToken, onMessage } from "firebase/messaging"

const firebaseConfig = {
  apiKey: "AIzaSyC3J4B-2aXLRbNo39gNdsVQ3C87Ta-R0ok",
  authDomain: "whappynotification.firebaseapp.com",
  projectId: "whappynotification",
  storageBucket: "whappynotification.appspot.com",
  messagingSenderId: "152843076527",
  appId: "1:152843076527:web:c3266df1f5ed894deda55a",
  measurementId: "G-MKXB3B7H35",
}

const firebaseApp = initializeApp(firebaseConfig)

const firebaseMessaging = getMessaging()
// onMessage(messaging, (payload) => {
//   console.log("Message received. ", payload)
//   // ...
// })
// getToken(messaging, {
//   vapidKey:
//     "BFpJYgBy9M2je-2cIdb0voL5pvOQvjxaXcwCkjg8AirDIIyBUks6Vu9Gw_ikyQ8Cxs-vV8zqwUAKNA5F0zMZdxE",
// })
//   .then((currentToken) => {
//     if (currentToken) {
//       console.log("🚀 ~ file: App.vue:63 ~ .then ~ currentToken:", currentToken)
//       // chiamata api per salvarla sullutente bello bellino7
//     } else {
//       console.log(
//         "No registration token available. Request permission to generate one."
//       )
//     }
//   })
//   .catch((err) => {
//     console.log("An error occurred while retrieving token. ", err)
//   })

export default { firebaseApp, firebaseMessaging }

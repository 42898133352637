import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";

import { createAnimation, IonicVue } from "@ionic/vue";
import VueSocialSharing from "vue-social-sharing";

// import { setupCalendar } from "v-calendar"

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import "./theme/basic.scss";
import "./theme/client.scss";

import "./registerServiceWorker";

import firebaseMessaging from "./firebase";

import { setupI18n } from "./i18n";
import en from "./locales/en.json";
import { setupRouter } from "./router";
import MasterLayout from "./components/MasterLayout.vue";
import store from "./store/index";
import clientConfig from "clientCommon/config";
import StorageService from "./common/mixins/Storage.vue";
import NotificationService from "./common/mixins/Notification.vue";
import TranslateService from "./common/mixins/Translate.vue";
import UtilService from "./common/mixins/Util.vue";

import veProgress from "vue-ellipse-progress";

import VueCookies from "vue-cookies";

import VueQrcodeReader from "vue3-qrcode-reader";

const pinia = createPinia();

const i18n = setupI18n({
  globalInjection: true,
  legacy: false,
  locale: "it",
  fallbackLocale: "en",
  messages: {
    en,
  },
});

// Initialize deferredPrompt for use later to show browser install prompt.
// https://web.dev/customize-install/#show_the_prompt
window.deferredPrompt = null;
window.addEventListener("beforeinstallprompt", (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // alert('testing: beforeinstallprompt triggered');
  // Stash the event so it can be triggered later.
  window.deferredPrompt = e;
  // Update UI notify the user they can install the PWA
  // showInstallPromotion();
  // Optionally, send analytics event that PWA install promo was shown.
  console.log(`'beforeinstallprompt' event was fired.`);
});

/**
 * baseEl is the navigation component (I.e. ion-nav),
 * but you likely will not need it.
 *
 * The opts object also has a `direction`
 * key which is either 'back' or 'forward' if you
 * wanted to do something special based on the direction.
 */
const myAnimation = (baseEl, opts) => {
  const { enteringEl, leavingEl } = opts;

  const enteringPage = createAnimation("entering-page-animation")
    .addElement(enteringEl)
    .fromTo("opacity", 1, 0);

  const leavingPage = createAnimation("leaving-page-animation")
    .addElement(leavingEl)
    .fromTo("opacity", 0, 1);

  return createAnimation("root-transition")
    .duration(500)
    .easing("ease-in-out")
    .addAnimation([enteringPage, leavingPage]);
};

// Finally create APP
const app = createApp({
  extends: App,
  mixins: [StorageService, NotificationService, TranslateService, UtilService],
});

// Client based imports
app.config.globalProperties.whappyClient = process.env.VUE_APP_WHAPPY_CLIENT;
app.config.globalProperties.whappyClientAsset =
  "../assets/clients/" + process.env.VUE_APP_WHAPPY_CLIENT + "/";
app.config.globalProperties.whappyClientConfig = clientConfig;
app.config.globalProperties.$messaging = firebaseMessaging.firebaseMessaging;
// console.log(clientConfig);
// if(process.env.NODE_ENV === 'development'){
//   app.config.globalProperties.whappyClientAsset = "../assets/clients/"+process.env.VUE_APP_WHAPPY_CLIENT+"/";
// }else{
//   app.config.globalProperties.whappyClientAsset = "../assets/c/";
// }

const router = setupRouter(app, i18n);

// App setup
app
  .use(pinia)
  .use(i18n)
  .use(IonicVue, {
    animated: false,
    // animated: true,
    // navAnimation: myAnimation
  })
  .use(VueSocialSharing)
  .use(router)
  .use(veProgress)
  .use(VueCookies)
  .use(VueQrcodeReader);
// .use(setupCalendar, {})

router.isReady().then(() => {
  app.mount("#app");
});

app.use(store);

app.component("master-layout", MasterLayout);
